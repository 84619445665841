import {
  CompleteIconsCategory,
  iconsCategories,
} from "@neurosolutionsgroup/models";

export type IconMap = Record<number, string>;

/**
 * Icon manager class to handle icon retrieval and fallback logic
 */
export class IconManager {
  private readonly icons: IconMap;
  private readonly fallbackIconId: number;

  constructor(icons: IconMap, fallbackIconId = 148) {
    this.icons = icons;
    this.fallbackIconId = fallbackIconId;
  }

  /**
   * Gets an icon by its ID, falling back to the default icon if not found
   * @param iconId - The ID of the icon to retrieve
   * @returns The icon URL
   */
  getIcon(iconId: number): string {
    return this.icons[iconId] || this.icons[this.fallbackIconId] || "";
  }

  /**
   * Checks if an icon exists
   * @param iconId - The ID of the icon to check
   * @returns True if the icon exists, false otherwise
   */
  hasIcon(iconId: number): boolean {
    return iconId in this.icons;
  }

  /**
   * Gets all available icon IDs
   * @returns Array of available icon IDs
   */
  getAvailableIconIds(): number[] {
    return Object.keys(this.icons).map(Number);
  }

  static getCategorizedIcons = (
    getCategoryText: (id: string) => string,
    hasIconsV202503: boolean
  ): CompleteIconsCategory[] => {
    return iconsCategories
      .map((category) => ({
        ...category,
        text: getCategoryText(category.id),
        icons: hasIconsV202503
          ? category.icons
          : category.icons.filter((icon) => icon < 120),
      }))
      .sort((a, b) => {
        const order = [
          "clothes",
          "food",
          "school",
          "cleaning",
          "objects",
          "activities",
          "characters",
          "symbols",
        ];
        return order.indexOf(a.id) - order.indexOf(b.id);
      });
  };
}
