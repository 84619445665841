import { HookResult } from "hooks/HookResult";
import { useCallback, useContext, useState } from "react";
import StripeContext from "./StripeContext";
import { getLocalCurrency } from "services/Location";
import { Coupon, LocalizationInfo, Price } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface ValidatePromoCodeResponse {
  type: "discount" | "free";
  couponData?: Coupon;
  metaData?: { [name: string]: string };
}

export interface useStripeSelectors {
  prices: Price[];
  referralCoupon: Coupon | null;
  localization?: LocalizationInfo;
}

export interface useStripeActions {
  activateFreeSubscription: (
    code: string,
    priceId: string
  ) => Promise<ValidatePromoCodeResponse>;
  getPrices: VoidFunction;
  getLocalization: VoidFunction;
  validatePromoCode: (code: string) => Promise<ValidatePromoCodeResponse>;
  getYearlyPrice: (currency: string, coupon?: Coupon) => Price | undefined;
  getMonthlyPrice: (currency: string) => Price | undefined;
}

const newPriceIds = [
  // Staging prices.
  "price_1QuHrkG5XNqJ1izvSyTVt8bo", // Yearly.
  "price_1QuHtKG5XNqJ1izvPKMml5Or", // Monthly
  // Production prices.
  "price_1QuJAmG5XNqJ1izvoW0de1ip", // Yearly.
  "price_1QuJBwG5XNqJ1izvsEjfMIpj", // Monthly.
];

const useStripe = (): HookResult<useStripeSelectors, useStripeActions> => {
  const {
    prices,
    setPrices,
    referralCoupon,
    setReferralCoupon,
    localization,
    setLocalization,
  } = useContext(StripeContext);

  const [gotPrices, setGotPrices] = useState(false);
  const [gotLocalization, setGotLocalization] = useState(false);

  const getPrices = async () => {
    if (!gotPrices) {
      FirebaseAPI.Billing.Stripe.getPrices().then((response) => {
        setPrices(response.prices.filter((p) => newPriceIds.includes(p.id)));
        setReferralCoupon(response.coupon ?? null);
        setGotPrices(true);
      });
    }
  };

  const getLocalization = async () => {
    if (!gotLocalization) {
      getLocalCurrency().then((response) => {
        setLocalization(response);
        setGotLocalization(true);
      });
    }
  };

  const validatePromoCode = async (
    code: string
  ): Promise<ValidatePromoCodeResponse> => {
    const promoCode = await FirebaseAPI.Billing.Stripe.validatePromoCode(code);

    if (promoCode.type === "discount" && promoCode.promoData) {
      return {
        type: promoCode.type,
        couponData: {
          id: promoCode.promoData.coupon.id,
          promoId: promoCode.promoData.id,
          name: promoCode.promoData.coupon.name ?? "",
          amount_off: promoCode.promoData.coupon.amount_off,
          currency: promoCode.promoData.coupon.currency,
          percent_off: promoCode.promoData.coupon.percent_off,
        },
        metaData: promoCode.promoData.metaData ?? undefined,
      };
    } else {
      return Promise.reject(new Error("Promo code response not recognised."));
    }
  };

  const activateFreeSubscription = async (
    code: string,
    priceId: string
  ): Promise<ValidatePromoCodeResponse> => {
    const promoCode = await FirebaseAPI.Billing.Stripe.activateFreePromoCode(
      code,
      priceId
    );

    if (promoCode.type === "free") {
      return promoCode;
    } else {
      return Promise.reject(new Error("Error activating account."));
    }
  };

  const getMonthlyPrice = useCallback(
    (currency: string): Price | undefined => {
      const monthly = prices.find((p) => p.interval === "month");

      if (!monthly) {
        return undefined;
      }

      const monthlyCopy = { ...monthly };

      return {
        ...monthlyCopy,
        unitAmount: monthlyCopy.currencyOptions?.[currency]?.unitAmount,
        unitAmountDecimal:
          monthlyCopy.currencyOptions?.[currency]?.unitAmountDecimal,
      };
    },
    [prices]
  );

  const getYearlyPrice = useCallback(
    (currency: string, coupon?: Coupon): Price | undefined => {
      const yearly = prices.find((p) => p.interval === "year");

      if (!yearly) {
        return undefined;
      }

      if (!coupon) {
        return {
          ...yearly,
          unitAmount: yearly.currencyOptions?.[currency]?.unitAmount,
          unitAmountDecimal:
            yearly.currencyOptions?.[currency]?.unitAmountDecimal,
        };
      }

      const yearlyCopy = yearly && { ...yearly };

      const unitAmount = yearlyCopy.currencyOptions?.[currency]?.unitAmount;

      if (unitAmount && coupon && coupon.percent_off) {
        yearlyCopy.unitAmount = Math.round(
          unitAmount * ((100 - coupon.percent_off) / 100)
        );
      } else if (
        coupon &&
        coupon.amount_off &&
        coupon.currency &&
        coupon.currency === currency
      ) {
        yearlyCopy.unitAmount = Math.round(unitAmount - coupon.amount_off);
      }

      return yearlyCopy;
    },
    [prices]
  );

  return {
    selectors: {
      prices,
      referralCoupon,
      localization,
    },
    actions: {
      activateFreeSubscription,
      getPrices,
      getLocalization,
      validatePromoCode,
      getYearlyPrice,
      getMonthlyPrice,
    },
  };
};

export default useStripe;
