import { Value } from "firebase/remote-config";
import semverGte from "semver/functions/gte";
import semverValid from "semver/functions/valid";
import { isV2FeatureFlag } from "./FeatureFlagV2";
import { FeatureFlagAudience } from "./FeatureFlagAudience";

const checkFeatureFlagVersion = (value: Value, version?: string): boolean => {
  const stringValue = value.asString();

  if (stringValue === "true") {
    return true;
  }

  if (version && semverValid(stringValue)) {
    return semverGte(version, stringValue);
  }

  return false;
};

const checkFeatureFlag = (
  value: Value,
  version?: string,
  userEmail?: string
): boolean => {
  const stringValue = value.asString();

  let featureFlag;

  try {
    featureFlag = JSON.parse(stringValue);
  } catch (error) {
    featureFlag = stringValue;
  }

  if (!featureFlag) {
    return false;
  }

  if (!isV2FeatureFlag(featureFlag)) {
    return checkFeatureFlagVersion(value, version);
  }

  if (version) {
    if (
      !(
        featureFlag.version === "true" ||
        (semverValid(featureFlag.version) &&
          semverGte(version, featureFlag.version))
      )
    ) {
      return false;
    }
  }

  if (featureFlag.audience === FeatureFlagAudience.None) {
    return false;
  }

  if (featureFlag.audience === FeatureFlagAudience.All) {
    return true;
  }

  if (!userEmail) {
    return false;
  }

  const audience = RegExp("@(neurosolutionsgroup|kairosgame)").test(userEmail)
    ? FeatureFlagAudience.Internal
    : FeatureFlagAudience.All;

  return featureFlag.audience === audience;
};

export default checkFeatureFlag;
