import { Language } from "@neurosolutionsgroup/models";
import { Value } from "firebase/remote-config";
import { ConfigNumber, FeatureFlag } from "../remoteConfigKeys";
import { useRemoteConfigContext } from "./remoteConfigContext";
import checkFeatureFlag from "./checkFeatureFlag";

interface UseRemoteConfigResult {
  checkFeatureFlag: (
    key: string,
    version?: string,
    userEmail?: string
  ) => boolean;
  getActivatedLanguages: () => Language[];
  getNumberConfig: (key: ConfigNumber) => number;
  getRemoteConfigValue: (key: string) => Value;
}

const useRemoteConfig = (): UseRemoteConfigResult => {
  const { getRemoteConfigValue } = useRemoteConfigContext();

  const getNumberConfig = (key: ConfigNumber): number => {
    return getRemoteConfigValue(key).asNumber();
  };

  const checkFeatureFlagHook = (
    key: string,
    version?: string,
    userEmail?: string
  ): boolean => {
    const value = getRemoteConfigValue(key);

    return checkFeatureFlag(value, version, userEmail);
  };

  const getActivatedLanguages = (): Language[] => {
    const languages: Language[] = [];

    if (getRemoteConfigValue(FeatureFlag.LanguageEN).asString() !== "false") {
      languages.push("en");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageFR).asString() !== "false") {
      languages.push("fr");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageDE).asString() !== "false") {
      languages.push("de");
    }

    return languages;
  };

  return {
    checkFeatureFlag: checkFeatureFlagHook,
    getActivatedLanguages,
    getNumberConfig,
    getRemoteConfigValue,
  };
};

export default useRemoteConfig;
