export enum Partners {
  GREEN_SHIELD = "greenshield",
  ORGANISATION = "organisation",
}

export enum PartnerPlan {
  YEAR_1 = "year-1",
  YEAR_2 = "year-2",
}

export interface PartnersInfo {
  partner: Partners;
  program: string;
  plan: PartnerPlan;
}
