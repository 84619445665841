import React from "react";
import { useTranslation } from "react-i18next";
import GreenCheckMark from "assets/GeneralIcons/green_checkmark.png";
import { Button } from "react-bootstrap";
import useStripe from "hooks/billing/useStripe";
import { Coupon, Price } from "@neurosolutionsgroup/models";

interface ProductCardProps {
  interval: "month" | "year";
  prices: Price[];
  currency: string;
  title: string;
  onClick: (priceId: string, discount?: boolean) => void;
  coupon?: Coupon;
}

const ProductCard: React.FC<ProductCardProps> = ({ coupon, ...props }) => {
  const { t, i18n } = useTranslation(["webappSettings"]);

  const {
    actions: { getMonthlyPrice, getYearlyPrice },
  } = useStripe();

  const getYearlyPriceWithoutCoupon = () =>
    getYearlyPrice(props.currency, undefined);

  const getYearlyPriceWithCoupon = () => getYearlyPrice(props.currency, coupon);

  const price = () => {
    if (props.interval === "year") {
      return getYearlyPriceWithCoupon();
    }

    return getMonthlyPrice(props.currency);
  };

  const currencySymbol = props.currency === "eur" ? "€" : "$";

  const calculateSavingPercentage = (): string => {
    const monthly =
      props.prices.find((p) => p.interval === "month")?.currencyOptions?.[
        props.currency
      ]?.unitAmount ?? 0;

    const yearly =
      getYearlyPriceWithCoupon()?.currencyOptions?.[props.currency]
        ?.unitAmount ?? 0;

    return (((monthly * 12 - yearly) / (monthly * 12)) * 100).toFixed(0);
  };

  return (
    <div
      className={"price-card__wrapper price-card__wrapper--" + props.interval}
    >
      <div
        className={
          "price-card__highlight price-card__highlight--" + props.interval
        }
      >
        <span>{t("webappSettings:billing.popular")}</span>
      </div>
      <div className={"price-card px-2 price-card-" + props.interval}>
        <h2>{props.title}</h2>
        <div
          className={
            "price-card__price " + (coupon ? "price-card__price--coupon" : "")
          }
        >
          {coupon ? (
            <div className="price-card__coupon">
              <i className="icon-tag"></i> <span>{coupon.name}</span>
            </div>
          ) : null}
          <div>
            <span className="price__amount">
              {currencySymbol}
              {(
                (price()?.unitAmount ?? 0) /
                100 /
                (props.interval === "year" ? 12 : 1)
              ).toLocaleString(i18n.language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
            <span className="price__interval">
              {t(
                "webappSettings:billing.interval." +
                  (props.interval === "year" ? "yearly" : "monthly")
              )}
            </span>
          </div>
          <div className="text-center mb-2">
            {props.interval === "year"
              ? t("webappSettings:billing.billing.yearly", {
                  amount: (
                    (price()?.currencyOptions?.[props.currency]?.unitAmount ??
                      0) / 100
                  ).toLocaleString(i18n.language),
                })
              : t("webappSettings:billing.billing.monthly")}
          </div>
          {coupon ? (
            <div className="referral-coupon-display">
              {!coupon ? (
                <p className="referral-coupon-display__amount-with-discount">
                  {t("webappSettings:billing.interval.yearlyPerYear", {
                    amount: (
                      (getYearlyPriceWithCoupon()?.currencyOptions?.[
                        props.currency
                      ]?.unitAmount ?? 0) / 100
                    ).toLocaleString(i18n.language, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }),
                  })}
                </p>
              ) : null}
              <p className="referral-coupon-display__amount">
                {t("webappSettings:billing.interval.yearlyPerYear", {
                  amount: (
                    (getYearlyPriceWithoutCoupon()?.currencyOptions?.[
                      props.currency
                    ]?.unitAmount ?? 0) / 100
                  ).toLocaleString(i18n.language, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }),
                })}
              </p>
            </div>
          ) : null}
        </div>
        <div className="my-3 d-flex flex-column">
          <p className="mb-3 marketing-title">
            {t("billing.marketing.title")} :
          </p>

          <div className="mx-auto">
            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.one")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.two")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.three")}</p>
            </div>
          </div>
        </div>
        <Button
          className="price-card__button"
          onClick={() => {
            const p = price();
            p && props.onClick(p.id, props.interval === "year");
          }}
        >
          {t("billing.buy")}
        </Button>
        <div className="deal-tag">
          <p>{t("billing.save")}</p>
          <p className="value">{calculateSavingPercentage()}%</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
