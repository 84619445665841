import { Currency, LocalizationInfo } from "@neurosolutionsgroup/models";

//  Detect location and corresponding currency using api call.
export const getLocalCurrency = async (): Promise<LocalizationInfo> => {
  if (process.env.NODE_ENV === "development") {
    return {
      currency: Currency.CAD,
      country: "Canada",
      countryCode: "CA",
      continent: "North America",
      region: "",
      ipAddress: "",
    };
  }

  return fetch("https://extreme-ip-lookup.com/json/?key=BE5c6GPuzkyOSjvQXYbR")
    .then((res) => res.json())
    .then((response) => {
      let currency: Currency;

      if (response.continent === "Europe") {
        currency = Currency.EUR;
      } else if (response.country === "Canada") {
        currency = Currency.CAD;
      } else {
        currency = Currency.USD;
      }

      return {
        currency: currency,
        country: response.country,
        countryCode: response.countryCode,
        continent: response.continent,
        region: response.region,
        ipAddress: response.query,
      };
    })
    .catch((err) => {
      console.error("Failed to get location", err);

      return {
        currency: Currency.USD,
        country: "",
        countryCode: "",
        continent: "",
        region: "",
        ipAddress: "",
      };
    });
};
