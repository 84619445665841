export enum WebviewsFeatureFlag {
  AbTestPaywall = "webviews_feature_flag_ab_test_paywall",
  ChildAnalytics = "webviews_feature_flag_child_analytics",
  DashboardExport = "webviews_feature_flag_dashboard_export",
  DefaultChildCreation = "webviews_feature_flag_default_child_creation",
  FtueChallengeCreation = "webviews_feature_flag_ftue_challenge_creation",
  GenericLinks = "webviews_feature_flag_generic_links",
  LocalizedGuideLinks = "webviews_feature_flag_localized_guide_links",
  MedicalGuide = "webviews_feature_flag_medical_guide",
  MedicalShare = "webviews_feature_flag_medical_share",
  NativeThirdPartyAuth = "webviews_feature_flag_native_third_party_auth",
  PaywallMonthlyTrial = "webviews_feature_flag_paywall_monthly_trial",
  SoundSettingsRemoved = "webviews_feature_flag_sound_settings_removed",
  DeleteChildData = "webviews_feature_flag_delete_child_data",
  ChildGroup = "webviews_feature_flag_child_group",
  ChatBot = "webviews_feature_flag_chatbot",
  FollowUpDataChangeWarning = "webviews_feature_flag_follow_up_data_change_warning",
  FollowUpDataExport = "webviews_feature_flag_follow_up_data_export",
  ShorterFTUE = "webviews_feature_flag_shorter_ftue",
  PrintableTools = "webviews_feature_flag_printable_tools",
}
