import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { Loader } from "@neurosolutionsgroup/components";
import { Coupon } from "@neurosolutionsgroup/models";
import { useStripe as useStripeHook } from "@stripe/react-stripe-js";
import { dangerNotification } from "components/notifications";
import useUserProfile from "hooks/account/useUserProfile";
import useStripe from "hooks/billing/useStripe";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { store } from "react-notifications-component";
import ProductCard from "./ProductCard";

interface ProductsProps {
  promoCode?: Coupon;
}

const Products: React.FC<ProductsProps> = ({ promoCode }) => {
  const { t } = useTranslation(["webappSettings"]);
  const [loading, setLoading] = useState(false);

  const {
    selectors: { prices, referralCoupon, localization },
  } = useStripe();

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const stripe = useStripeHook();

  const checkout = async (priceId: string, discount = false): Promise<void> => {
    if (localization) {
      setLoading(true);
      FirebaseAPI.Billing.Stripe.createCheckoutSession(
        priceId,
        localization,
        promoCode?.promoId ?? promoCode?.id ?? undefined
      )
        .then((response) => {
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: response.sessionId,
            });
          } else {
            console.error("No Stripe instance found.");
          }
        })
        .catch((err) => {
          setLoading(false);

          store.addNotification(
            dangerNotification(
              t("webappBase:forms.errors.title"),
              t("webappBase:forms.errors.general") +
                ": " +
                (err instanceof Error)
                ? err.message
                : t("webappBase:forms.errors.unknown"),
              false
            )
          );
        });
    }
  };

  const getReferralInformation = useCallback((): Coupon | undefined => {
    return userProfile?.referralData?.referrer?.referrerName && referralCoupon
      ? referralCoupon
      : undefined;
  }, [userProfile, referralCoupon]);

  return (
    <>
      {prices.length > 0 && localization ? (
        <>
          {loading && <Loader />}
          <Row className="mt-3 justify-content-center">
            <Col md={6} lg={5}>
              <ProductCard
                interval="month"
                prices={prices}
                currency={localization.currency}
                title={t("billing.producttype.monthly")}
                onClick={checkout}
              />
            </Col>
            <Col md={6} lg={5}>
              <ProductCard
                interval="year"
                prices={prices}
                currency={localization.currency}
                title={t("billing.producttype.yearly")}
                onClick={checkout}
                coupon={promoCode ?? getReferralInformation()}
              />
            </Col>
          </Row>
          {!promoCode ? (
            <Row className="justify-content-center my-3 subscription-discount-clause">
              <Col md={8}>
                <p className="text-center">
                  <Trans i18nKey={"webappSettings:billing.discount"}>
                    0 <a href="mailto:support@kairosgame.com">1</a> 2
                  </Trans>
                </p>
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Products;
