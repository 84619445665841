import { v4 } from "uuid";
import { Routine, Task, prefilledTaskCategoriesMap } from ".";
import { Language } from "../language";

export enum Template {
  Other = 0,
  Morning = 1,
  Midday = 2,
  Afternoon = 3,
  Evening = 4,
}

export type TemplateLocType = {
  en: {
    [x: number]: string;
  };
  fr: {
    [x: number]: string;
  };
  de: {
    [x: number]: string;
  };
};

// Map of task indexes to names.
export type TaskLocType = {
  en: {
    [iconId: string]: { text: string }[];
  };
  fr: {
    [iconId: string]: { text: string }[];
  };
  de: {
    [iconId: string]: { text: string }[];
  };
};

export type TaskCategoryLoc = {
  en: {
    [x: string]: string;
  };
  fr: {
    [x: string]: string;
  };
  de: {
    [x: string]: string;
  };
};

type RoutineTemplate = {
  id: number;
  icon: 0 | 1 | 2 | 3 | 4;
  // Days represented by first 7 binary digits in a byte (Monday = 1, Sunday = 64), e.g. all days = 127
  days: number;
  // Seconds past midnight.
  start: number;
  // Seconds past midnight.
  end: number;
  // Seconds before start.
  alert: number | null;
  tasks: { icon: number; nameIndex: number }[];
};

type TemplateDefinitions = {
  [key in Template]: RoutineTemplate;
};

export const templates: TemplateDefinitions = {
  [Template.Other]: {
    id: 0,
    icon: 0,
    days: 0,
    start: 5 * 3600,
    end: 23 * 3600,
    alert: 600,
    tasks: [],
  },
  [Template.Morning]: {
    id: 1,
    icon: 1,
    days: 31,
    start: 7 * 3600,
    end: 8 * 3600,
    alert: 600,
    tasks: [
      { icon: 9, nameIndex: 0 },
      { icon: 2, nameIndex: 0 },
      { icon: 49, nameIndex: 0 },
      { icon: 5, nameIndex: 0 },
      { icon: 3, nameIndex: 0 },
    ],
  },
  [Template.Midday]: {
    id: 2,
    icon: 2,
    days: 31,
    start: 11.5 * 3600,
    end: 12.5 * 3600,
    alert: 600,
    tasks: [
      { icon: 11, nameIndex: 2 },
      { icon: 10, nameIndex: 0 },
      { icon: 36, nameIndex: 0 },
      { icon: 3, nameIndex: 0 },
    ],
  },
  [Template.Afternoon]: {
    id: 3,
    icon: 3,
    days: 31,
    start: 16 * 3600,
    end: 17 * 3600,
    alert: 600,
    tasks: [
      { icon: 24, nameIndex: 0 },
      { icon: 11, nameIndex: 1 },
      { icon: 16, nameIndex: 0 },
      { icon: 15, nameIndex: 0 },
    ],
  },
  [Template.Evening]: {
    id: 4,
    icon: 4,
    days: 127,
    start: 19 * 3600,
    end: 20 * 3600,
    alert: 600,
    tasks: [
      { icon: 8, nameIndex: 0 },
      { icon: 22, nameIndex: 0 },
      { icon: 3, nameIndex: 0 },
      { icon: 25, nameIndex: 0 },
      { icon: 26, nameIndex: 0 },
    ],
  },
};

export enum TaskCategory {
  Autonomy = "1",
  Hygiene = "2",
  FollowingInstructions = "3",
  Household = "5",
  Tidying = "6",
  HealthAndWellbeing = "7",
  FamilyLife = "8",
}

export const templateToRoutine = (
  template: Template,
  userIds: string[],
  language: Language,
  taskLoc: TaskLocType,
  routineLoc: TemplateLocType
): Routine => {
  const templateInfo = templates[template];
  const tasks: Task[] = [];

  const routineId = v4();

  templateInfo.tasks.forEach((ti, i) => {
    tasks.push({
      id: v4(),
      name: taskLoc[language][ti.icon.toString()][ti.nameIndex].text,
      icon: ti.icon,
      reminder: 0,
      routine: routineId,
      creation: Math.floor(Date.now() / 1000),
      index: i,
      category: prefilledTaskCategoriesMap[ti.icon][ti.nameIndex],
    });
  });

  return {
    id: routineId,
    version: 0,
    templateId: template,
    icon: templateInfo.icon,
    // "Other" template has no name by default.
    name: template === Template.Other ? "" : routineLoc[language][template],
    days: templateInfo.days,
    start: templateInfo.start,
    end: templateInfo.end,
    alert: templateInfo.alert,
    users: userIds,
    tasks: tasks,
    inactivePeriods: [],
    disabled: null,
    deleted: null,
    locked: null,
    lastDisabledDate: null,
    deletedDate: null,
  };
};
