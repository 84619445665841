import { Partners, PartnersInfo } from "./Partners";
import { PartnerInfoArgs } from "./CreatePartnerUserArgs";

export interface GreenShieldPartnerInfo extends PartnersInfo {
  partner: Partners.GREEN_SHIELD;
  certificateNumber: string;
  program: GreenShieldProgram;
}

export interface GreenShieldPartnerInfoArgs extends PartnerInfoArgs {
  partner: Partners.GREEN_SHIELD;
  certificateNumber: string;
  program: GreenShieldProgram;
}

export const isGreenShieldPartnerInfoArgs = (
  args: PartnerInfoArgs
): args is GreenShieldPartnerInfoArgs => args.partner === Partners.GREEN_SHIELD;

export enum GreenShieldProgram {
  INITIAL_OFFER = "initial-offer",
}

export const isGreenShieldProgram = (
  program: unknown
): program is GreenShieldProgram =>
  Object.values(GreenShieldProgram).includes(program as GreenShieldProgram);
