import { FeatureFlagAudience } from "./FeatureFlagAudience";

export interface FeatureFlagV2 {
  version: string;
  audience: FeatureFlagAudience;
}

export const isV2FeatureFlag = (value: unknown): value is FeatureFlagV2 => {
  return (
    typeof value === "object" &&
    value !== null &&
    "version" in value &&
    "audience" in value
  );
};
