export type PrefilledTaskCategoriesMap = {
  [iconId: string]: {
    [textId: string]: string;
  };
};

/**
 * Map of icons with their predefined text indexes, and the category ID for each text.
 */
export const prefilledTaskCategoriesMap: PrefilledTaskCategoriesMap = {
  "1": {
    "0": "1",
  },
  "2": {
    "0": "6",
  },
  "3": {
    "0": "2",
  },
  "4": {
    "0": "2",
  },
  "5": {
    "0": "7",
  },
  "6": {
    "0": "7",
  },
  "7": {
    "0": "5",
    "1": "5",
    "2": "7",
  },
  "8": {
    "0": "2",
  },
  "9": {
    "0": "1",
  },
  "10": {
    "0": "1",
  },
  "11": {
    "0": "1",
    "1": "6",
    "2": "7",
  },
  "12": {
    "0": "3",
    "1": "6",
    "2": "6",
  },
  "13": {
    "0": "3",
  },
  "14": {
    "0": "5",
    "1": "5",
  },
  "15": {
    "0": "1",
  },
  "16": {
    "0": "3",
    "1": "3",
    "2": "3",
  },
  "17": {
    "0": "1",
  },
  "18": {
    "0": "1",
    "1": "1",
  },
  "19": {
    "0": "6",
  },
  "20": {
    "0": "1",
    "1": "6",
    "2": "6",
  },
  "21": {
    "0": "6",
  },
  "22": {
    "0": "1",
  },
  "23": {
    "0": "2",
    "1": "2",
    "2": "2",
  },
  "24": {
    "0": "7",
  },
  "25": {
    "0": "3",
  },
  "26": {
    "0": "3",
  },
  "27": {
    "0": "6",
    "1": "1",
    "2": "1",
  },
  "28": {
    "0": "5",
    "1": "5",
  },
  "29": {
    "0": "5",
  },
  "30": {
    "0": "5",
  },
  "31": {
    "0": "8",
  },
  "32": {
    "0": "8",
  },
  "33": {
    "0": "5",
  },
  "34": {
    "0": "6",
    "1": "1",
  },
  "35": {
    "0": "2",
  },
  "36": {
    "0": "2",
  },
  "37": {
    "0": "2",
    "1": "2",
    "2": "2",
  },
  "38": {
    "0": "2",
  },
  "39": {
    "0": "1",
    "1": "2",
    "2": "2",
  },
  "40": {
    "0": "1",
  },
  "41": {
    "0": "3",
  },
  "42": {
    "0": "1",
  },
  "43": {
    "0": "3",
  },
  "44": {
    "0": "3",
  },
  "45": {
    "0": "1",
  },
  "46": {
    "0": "6",
    "1": "6",
  },
  "47": {
    "0": "5",
  },
  "48": {
    "0": "5",
  },
  "49": {
    "0": "1",
  },
  "50": {
    "0": "7",
  },
  "51": {
    "0": "1",
  },
  "52": {
    "0": "5",
    "1": "5",
  },
  "53": {
    "0": "7",
  },
  "54": {
    "0": "7",
  },
  "55": {
    "0": "7",
  },
  "56": {
    "0": "7",
  },
  "57": {
    "0": "7",
  },
  "58": {
    "0": "1",
  },
  "59": {
    "0": "7",
    "1": "7",
  },
  "60": {
    "0": "5",
    "1": "5",
  },
  "61": {
    "0": "1",
  },
  "62": {
    "0": "8",
    "1": "8",
  },
  "63": {
    "0": "2",
  },
  "64": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "65": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "66": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "67": {
    "0": "1",
    "1": "6",
  },
  "68": {
    "0": "6",
    "1": "1",
  },
  "69": {
    "0": "2",
  },
  "70": {
    "0": "5",
    "1": "5",
  },
  "71": {
    "0": "5",
    "1": "5",
  },
  "72": {
    "0": "3",
  },
  "73": {
    "0": "7",
    "1": "3",
  },
  "74": {
    "0": "7",
  },
  "75": {
    "0": "7",
  },
  "76": {
    "0": "1",
    "1": "1",
  },
  "77": {
    "0": "7",
  },
  "78": {
    "0": "3",
  },
  "79": {
    "0": "1",
  },
  "80": {
    "0": "1",
  },
  "81": {
    "0": "1",
  },
  "82": {
    "0": "8",
    "1": "8",
  },
  "83": {
    "0": "8",
    "1": "8",
  },
  "84": {
    "0": "8",
  },
  "85": {
    "0": "8",
  },
  "86": {
    "0": "8",
    "1": "8",
  },
  "87": {
    "0": "8",
    "1": "8",
  },
  "88": {
    "0": "8",
    "1": "8",
  },
  "89": {
    "0": "8",
    "1": "8",
  },
  "90": {
    "0": "8",
  },
  "91": {
    "0": "8",
  },
  "92": {
    "0": "8",
    "1": "8",
  },
  "93": {
    "0": "8",
    "1": "8",
  },
  "94": {
    "0": "8",
  },
  "95": {
    "0": "8",
  },
  "96": {
    "0": "8",
    "1": "8",
  },
  "97": {
    "0": "8",
    "1": "8",
  },
  "98": {
    "0": "8",
  },
  "99": {
    "0": "8",
  },
  "100": {
    "0": "8",
  },
  "101": {
    "0": "8",
  },
  "102": {
    "0": "8",
    "1": "8",
  },
  "103": {
    "0": "8",
    "1": "8",
  },
  "104": {
    "0": "8",
  },
  "105": {
    "0": "8",
  },
  "106": {
    "0": "7",
  },
  "107": {
    "0": "7",
  },
  "108": {
    "0": "1",
    "1": "1",
  },
  "109": {
    "0": "1",
    "1": "1",
  },
  "110": {
    "0": "1",
    "1": "1",
  },
  "111": {
    "0": "1",
  },
  "112": {
    "0": "1",
  },
  "113": {
    "0": "1",
    "1": "1",
  },
  "114": {
    "0": "1",
    "1": "1",
  },
  "115": {
    "0": "1",
    "1": "1",
  },
  "116": {
    "0": "1",
    "1": "1",
  },
  "117": {
    "0": "1",
    "1": "1",
  },
  "118": {
    "0": "1",
    "1": "1",
  },
  "119": {
    "0": "1",
    "1": "1",
  },
  "120": {
    "0": "3",
  },
  "121": {
    "0": "7",
  },
  "122": {
    "0": "1",
    "1": "1",
  },
  "123": {
    "0": "3",
  },
  "124": {
    "0": "8",
    "1": "8",
  },
  "125": {
    "0": "3",
  },
  "126": {
    "0": "5",
    "1": "5",
  },
  "127": {
    "0": "1",
    "1": "1",
    "2": "1",
  },
  "128": {
    "0": "5",
    "1": "5",
  },
  "129": {
    "0": "8",
  },
  "130": {
    "0": "8",
  },
  "131": {
    "0": "5",
  },
  "132": {
    "0": "6",
    "1": "6",
  },
  "133": {
    "0": "1",
  },
  "134": {
    "0": "8",
    "1": "8",
    "2": "8",
  },
  "135": {
    "0": "7",
    "1": "7",
  },
  "136": {
    "0": "3",
    "1": "1",
  },
  "137": {
    "0": "7",
    "1": "7",
  },
  "138": {
    "0": "1",
    "1": "1",
    "2": "1",
    "3": "1",
  },
  "139": {
    "0": "1",
    "1": "1",
    "2": "1",
    "3": "1",
  },
  "140": {
    "0": "1",
  },
  "141": {
    "0": "1",
    "1": "1",
  },
  "142": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
    "4": "7",
  },
  "143": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
  },
  "144": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
  },
  "145": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
    "4": "7",
    "5": "7",
  },
  "146": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
    "4": "7",
  },
  "147": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
  },
  "148": {
    "0": "3",
  },
  "149": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
    "4": "7",
  },
  "150": {
    "0": "3",
    "1": "3",
  },
  "151": {
    "0": "1",
    "1": "1",
  },
  "152": {
    "0": "7",
    "1": "7",
    "2": "7",
    "3": "7",
    "4": "7",
  },
  "153": {
    "0": "3",
  },
  "154": {
    "0": "1",
    "1": "1",
    "2": "1",
    "3": "1",
    "4": "1",
  },
  "155": {
    "0": "3",
    "1": "3",
    "2": "3",
  },
};

export type IconsCategory = {
  id: string;
  icons: number[];
};

export type CompleteIconsCategory = IconsCategory & {
  text: string;
};

export const iconsCategories: IconsCategory[] = [
  {
    id: "activities",
    icons: [25, 50, 73, 120, 74, 75, 121, 57, 51, 59, 76, 122, 78, 79],
  },
  {
    id: "food",
    icons: [7, 12, 77, 24, 123, 5, 124, 125, 126],
  },
  {
    id: "school",
    icons: [11, 16, 15, 17, 18, 20, 43, 44, 45, 127],
  },
  {
    id: "cleaning",
    icons: [29, 71, 128, 14, 70, 21, 19, 28, 60, 52, 46, 30, 47],
  },
  {
    id: "objects",
    icons: [
      9, 2, 10, 3, 39, 8, 63, 36, 37, 23, 38, 61, 69, 4, 35, 6, 135, 106, 107,
      27, 34, 72, 33, 129, 130, 131, 132, 48, 133, 134, 155, 136, 138, 139, 140,
      141, 41,
    ],
  },
  {
    id: "characters",
    icons: [
      13, 53, 54, 55, 56, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
      93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 142, 143, 144,
      145, 146, 31, 32, 62, 147,
    ],
  },
  {
    id: "symbols",
    icons: [26, 154, 149, 150, 151, 152, 153, 137, 148],
  },
  {
    id: "clothes",
    icons: [
      49, 22, 1, 42, 64, 67, 65, 66, 40, 68, 58, 108, 109, 110, 111, 112, 113,
      114, 115, 116, 117, 118, 119,
    ],
  },
];
